/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum TransactionFailureReason {
    SIMULATION_ERROR = 'SIMULATION_ERROR',
    UNSUPPORTED_SIMULATION = 'UNSUPPORTED_SIMULATION',
    SIMULATION_UNAVAILABLE = 'SIMULATION_UNAVAILABLE',
    SLIPPAGE_TOO_LOW = 'SLIPPAGE_TOO_LOW',
}
