export function executeWithFrameDelay(firstAction, secondAction, frames = 4) {
  firstAction();
  const executeAfterFrames = (remainingFrames) => {
    if (remainingFrames <= 0) {
      secondAction();
    } else {
      requestAnimationFrame(() => executeAfterFrames(remainingFrames - 1));
    }
  };
  executeAfterFrames(frames);
}
