"use strict";
import { AddressZero } from "@ethersproject/constants";
import { ONE, Trade } from "@uniswap/router-sdk";
import { CurrencyAmount, Fraction, Percent, Price, TradeType } from "@uniswap/sdk-core";
import {
  DutchOrderTrade as IDutchOrderTrade,
  PriorityOrderTrade as IPriorityOrderTrade,
  V2DutchOrderTrade as IV2DutchOrderTrade
} from "@uniswap/uniswapx-sdk";
import { ZERO_PERCENT } from "constants/misc";
import { BigNumber } from "ethers/lib/ethers";
export var TradeState = /* @__PURE__ */ ((TradeState2) => {
  TradeState2["LOADING"] = "loading";
  TradeState2["INVALID"] = "invalid";
  TradeState2["STALE"] = "stale";
  TradeState2["NO_ROUTE_FOUND"] = "no_route_found";
  TradeState2["VALID"] = "valid";
  return TradeState2;
})(TradeState || {});
export var QuoteMethod = /* @__PURE__ */ ((QuoteMethod2) => {
  QuoteMethod2["ROUTING_API"] = "ROUTING_API";
  QuoteMethod2["QUICK_ROUTE"] = "QUICK_ROUTE";
  QuoteMethod2["CLIENT_SIDE_FALLBACK"] = "CLIENT_SIDE_FALLBACK";
  return QuoteMethod2;
})(QuoteMethod || {});
export const INTERNAL_ROUTER_PREFERENCE_PRICE = "price";
const GAS_ESTIMATE_BUFFER = 1.15;
export var RouterPreference = /* @__PURE__ */ ((RouterPreference2) => {
  RouterPreference2["X"] = "uniswapx";
  RouterPreference2["API"] = "api";
  return RouterPreference2;
})(RouterPreference || {});
export var QuoteIntent = /* @__PURE__ */ ((QuoteIntent2) => {
  QuoteIntent2["Pricing"] = "pricing";
  QuoteIntent2["Quote"] = "quote";
  return QuoteIntent2;
})(QuoteIntent || {});
export function isClassicQuoteResponse(data) {
  return data.routing === "CLASSIC" /* CLASSIC */;
}
export var TradeFillType = /* @__PURE__ */ ((TradeFillType2) => {
  TradeFillType2["Classic"] = "classic";
  TradeFillType2["UniswapX"] = "uniswap_x";
  TradeFillType2["UniswapXv2"] = "uniswap_x_v2";
  TradeFillType2["None"] = "none";
  return TradeFillType2;
})(TradeFillType || {});
export class ClassicTrade extends Trade {
  fillType = "classic" /* Classic */;
  approveInfo;
  gasUseEstimate;
  // gas estimate for swaps
  gasUseEstimateUSD;
  // gas estimate for swaps in USD
  blockNumber;
  requestId;
  quoteMethod;
  swapFee;
  constructor({
    gasUseEstimate,
    gasUseEstimateUSD,
    blockNumber,
    requestId,
    quoteMethod,
    approveInfo,
    swapFee,
    ...routes
  }) {
    super(routes);
    this.blockNumber = blockNumber;
    this.gasUseEstimateUSD = gasUseEstimateUSD;
    this.requestId = requestId;
    this.quoteMethod = quoteMethod;
    this.approveInfo = approveInfo;
    this.swapFee = swapFee;
    this.gasUseEstimate = gasUseEstimate;
  }
  get executionPrice() {
    if (this.tradeType === TradeType.EXACT_INPUT || !this.swapFee) {
      return super.executionPrice;
    }
    return new Price({ baseAmount: this.inputAmount, quoteAmount: this.postSwapFeeOutputAmount });
  }
  get postSwapFeeOutputAmount() {
    if (this.tradeType === TradeType.EXACT_INPUT) {
      return this.outputAmount;
    }
    const swapFeeAmount = CurrencyAmount.fromRawAmount(this.outputAmount.currency, this.swapFee?.amount ?? 0);
    return this.outputAmount.subtract(swapFeeAmount);
  }
  // gas estimate for maybe approve + swap
  get totalGasUseEstimateUSD() {
    if (this.approveInfo.needsApprove && this.gasUseEstimateUSD) {
      return this.approveInfo.approveGasEstimateUSD + this.gasUseEstimateUSD;
    }
    return this.gasUseEstimateUSD;
  }
  get totalGasUseEstimateUSDWithBuffer() {
    return this.totalGasUseEstimateUSD ? this.totalGasUseEstimateUSD * GAS_ESTIMATE_BUFFER : 0;
  }
}
export var OffchainOrderType = /* @__PURE__ */ ((OffchainOrderType2) => {
  OffchainOrderType2["DUTCH_AUCTION"] = "Dutch";
  OffchainOrderType2["DUTCH_V2_AUCTION"] = "Dutch_V2";
  OffchainOrderType2["LIMIT_ORDER"] = "Limit";
  OffchainOrderType2["DUTCH_V1_AND_V2"] = "Dutch_V1_V2";
  OffchainOrderType2["PRIORITY_ORDER"] = "Priority";
  return OffchainOrderType2;
})(OffchainOrderType || {});
export class DutchOrderTrade extends IDutchOrderTrade {
  fillType = "uniswap_x" /* UniswapX */;
  offchainOrderType = "Dutch" /* DUTCH_AUCTION */;
  quoteId;
  requestId;
  wrapInfo;
  approveInfo;
  // The gas estimate of the reference classic trade, if there is one.
  classicGasUseEstimateUSD;
  auctionPeriodSecs;
  startTimeBufferSecs;
  deadlineBufferSecs;
  slippageTolerance;
  inputTax = ZERO_PERCENT;
  outputTax = ZERO_PERCENT;
  swapFee;
  constructor({
    currencyIn,
    currenciesOut,
    orderInfo,
    tradeType,
    quoteId,
    requestId,
    wrapInfo,
    approveInfo,
    classicGasUseEstimateUSD,
    auctionPeriodSecs,
    startTimeBufferSecs,
    deadlineBufferSecs,
    slippageTolerance,
    swapFee
  }) {
    super({ currencyIn, currenciesOut, orderInfo, tradeType });
    this.quoteId = quoteId;
    this.requestId = requestId;
    this.approveInfo = approveInfo;
    this.wrapInfo = wrapInfo;
    this.classicGasUseEstimateUSD = classicGasUseEstimateUSD;
    this.auctionPeriodSecs = auctionPeriodSecs;
    this.deadlineBufferSecs = deadlineBufferSecs;
    this.slippageTolerance = slippageTolerance;
    this.startTimeBufferSecs = startTimeBufferSecs;
    this.swapFee = swapFee;
  }
  get totalGasUseEstimateUSD() {
    if (this.wrapInfo.needsWrap && this.approveInfo.needsApprove) {
      return this.wrapInfo.wrapGasEstimateUSD + this.approveInfo.approveGasEstimateUSD;
    }
    if (this.wrapInfo.needsWrap) {
      return this.wrapInfo.wrapGasEstimateUSD;
    }
    if (this.approveInfo.needsApprove) {
      return this.approveInfo.approveGasEstimateUSD;
    }
    return 0;
  }
  /**
   * Ensures that LimitOrderTrade conforms to the same interface as DutchOrderTrade
   * By using trade.asDutchOrderTrade(), we can uniformly handle both trade types without needing to verify their specific class type.
   */
  asDutchOrderTrade() {
    return this;
  }
}
export class V2DutchOrderTrade extends IV2DutchOrderTrade {
  fillType = "uniswap_x_v2" /* UniswapXv2 */;
  offchainOrderType = "Dutch_V2" /* DUTCH_V2_AUCTION */;
  quoteId;
  requestId;
  wrapInfo;
  approveInfo;
  // The gas estimate of the reference classic trade, if there is one.
  classicGasUseEstimateUSD;
  deadlineBufferSecs;
  slippageTolerance;
  inputTax = ZERO_PERCENT;
  outputTax = ZERO_PERCENT;
  swapFee;
  forceOpenOrder;
  constructor({
    currencyIn,
    currenciesOut,
    orderInfo,
    tradeType,
    quoteId,
    requestId,
    wrapInfo,
    approveInfo,
    classicGasUseEstimateUSD,
    deadlineBufferSecs,
    slippageTolerance,
    swapFee,
    forceOpenOrder
  }) {
    super({ currencyIn, currenciesOut, orderInfo, tradeType });
    this.quoteId = quoteId;
    this.requestId = requestId;
    this.approveInfo = approveInfo;
    this.wrapInfo = wrapInfo;
    this.classicGasUseEstimateUSD = classicGasUseEstimateUSD;
    this.deadlineBufferSecs = deadlineBufferSecs;
    this.slippageTolerance = slippageTolerance;
    this.swapFee = swapFee;
    this.forceOpenOrder = forceOpenOrder;
  }
  get totalGasUseEstimateUSD() {
    if (this.wrapInfo.needsWrap && this.approveInfo.needsApprove) {
      return this.wrapInfo.wrapGasEstimateUSD + this.approveInfo.approveGasEstimateUSD;
    }
    if (this.wrapInfo.needsWrap) {
      return this.wrapInfo.wrapGasEstimateUSD;
    }
    if (this.approveInfo.needsApprove) {
      return this.approveInfo.approveGasEstimateUSD;
    }
    return 0;
  }
}
export class PriorityOrderTrade extends IPriorityOrderTrade {
  fillType = "uniswap_x" /* UniswapX */;
  offchainOrderType = "Priority" /* PRIORITY_ORDER */;
  quoteId;
  requestId;
  wrapInfo;
  approveInfo;
  // The gas estimate of the reference classic trade, if there is one.
  classicGasUseEstimateUSD;
  startTimeBufferSecs;
  deadlineBufferSecs;
  slippageTolerance;
  inputTax = ZERO_PERCENT;
  outputTax = ZERO_PERCENT;
  swapFee;
  constructor({
    currencyIn,
    currenciesOut,
    orderInfo,
    tradeType,
    quoteId,
    requestId,
    wrapInfo,
    approveInfo,
    classicGasUseEstimateUSD,
    startTimeBufferSecs,
    deadlineBufferSecs,
    slippageTolerance,
    swapFee
  }) {
    super({ currencyIn, currenciesOut, orderInfo, tradeType });
    this.quoteId = quoteId;
    this.requestId = requestId;
    this.approveInfo = approveInfo;
    this.wrapInfo = wrapInfo;
    this.classicGasUseEstimateUSD = classicGasUseEstimateUSD;
    this.deadlineBufferSecs = deadlineBufferSecs;
    this.slippageTolerance = slippageTolerance;
    this.startTimeBufferSecs = startTimeBufferSecs;
    this.swapFee = swapFee;
  }
  get totalGasUseEstimateUSD() {
    if (this.wrapInfo.needsWrap && this.approveInfo.needsApprove) {
      return this.wrapInfo.wrapGasEstimateUSD + this.approveInfo.approveGasEstimateUSD;
    }
    if (this.wrapInfo.needsWrap) {
      return this.wrapInfo.wrapGasEstimateUSD;
    }
    if (this.approveInfo.needsApprove) {
      return this.approveInfo.approveGasEstimateUSD;
    }
    return 0;
  }
}
export class PreviewTrade {
  fillType = "none" /* None */;
  quoteMethod = "QUICK_ROUTE" /* QUICK_ROUTE */;
  tradeType;
  inputAmount;
  outputAmount;
  constructor({
    inputAmount,
    outputAmount,
    tradeType
  }) {
    this.inputAmount = inputAmount;
    this.outputAmount = outputAmount;
    this.tradeType = tradeType;
  }
  // below methods are copied from router-sdk
  // Trade https://github.com/Uniswap/router-sdk/blob/main/src/entities/trade.ts#L10
  minimumAmountOut(slippageTolerance, amountOut = this.outputAmount) {
    if (this.tradeType === TradeType.EXACT_OUTPUT) {
      return amountOut;
    } else {
      const slippageAdjustedAmountOut = new Fraction(ONE).add(slippageTolerance).invert().multiply(amountOut.quotient).quotient;
      return CurrencyAmount.fromRawAmount(amountOut.currency, slippageAdjustedAmountOut);
    }
  }
  maximumAmountIn(slippageTolerance, amountIn = this.inputAmount) {
    if (this.tradeType === TradeType.EXACT_INPUT) {
      return amountIn;
    } else {
      const slippageAdjustedAmountIn = new Fraction(ONE).add(slippageTolerance).multiply(amountIn.quotient).quotient;
      return CurrencyAmount.fromRawAmount(amountIn.currency, slippageAdjustedAmountIn);
    }
  }
  /**
   * Returns the sell tax of the input token
   */
  get inputTax() {
    const inputCurrency = this.inputAmount.currency;
    if (inputCurrency.isNative || !inputCurrency.wrapped.sellFeeBps) {
      return ZERO_PERCENT;
    }
    return new Percent(inputCurrency.wrapped.sellFeeBps.toNumber(), 1e4);
  }
  /**
   * Returns the buy tax of the output token
   */
  get outputTax() {
    const outputCurrency = this.outputAmount.currency;
    if (outputCurrency.isNative || !outputCurrency.wrapped.buyFeeBps) {
      return ZERO_PERCENT;
    }
    return new Percent(outputCurrency.wrapped.buyFeeBps.toNumber(), 1e4);
  }
  _executionPrice;
  /**
   * The price expressed in terms of output amount/input amount.
   */
  get executionPrice() {
    return this._executionPrice ?? (this._executionPrice = new Price(
      this.inputAmount.currency,
      this.outputAmount.currency,
      this.inputAmount.quotient,
      this.outputAmount.quotient
    ));
  }
  worstExecutionPrice(slippageTolerance) {
    return new Price(
      this.inputAmount.currency,
      this.outputAmount.currency,
      this.maximumAmountIn(slippageTolerance).quotient,
      this.minimumAmountOut(slippageTolerance).quotient
    );
  }
}
const UNISWAPX_REACTOR = "0x6000da47483062a0d734ba3dc7576ce6a0b645c4";
export class LimitOrderTrade {
  fillType = "uniswap_x" /* UniswapX */;
  offchainOrderType = "Limit" /* LIMIT_ORDER */;
  deadlineBufferSecs;
  wrapInfo;
  approveInfo;
  swapFee;
  amountIn;
  amountOut;
  tradeType;
  swapper;
  deadline;
  // Placeholder values that aren't used in a limit trade
  inputTax = ZERO_PERCENT;
  outputTax = ZERO_PERCENT;
  slippageTolerance = ZERO_PERCENT;
  quoteId = void 0;
  requestId = void 0;
  constructor({
    tradeType,
    amountIn,
    amountOut,
    deadlineBufferSecs,
    swapFee,
    wrapInfo,
    approveInfo,
    swapper
  }) {
    this.deadlineBufferSecs = deadlineBufferSecs;
    this.swapFee = swapFee;
    this.wrapInfo = wrapInfo;
    this.approveInfo = approveInfo;
    this.amountIn = amountIn;
    this.amountOut = amountOut;
    this.tradeType = tradeType;
    this.swapper = swapper;
    const nowSecs = Math.floor(Date.now() / 1e3);
    this.deadline = (nowSecs + deadlineBufferSecs) * 1e3;
  }
  /**
   * Ensures that LimitOrderTrade conforms to the same interface as DutchOrderTrade
   * By using trade.asDutchOrderTrade(), we can uniformly handle both trade types without needing to verify their specific class type.
   */
  asDutchOrderTrade(options) {
    const swapperOutput = {
      token: this.amountOut.currency.isNative ? AddressZero : this.amountOut.currency.address,
      recipient: options?.swapper ?? this.swapper,
      startAmount: BigNumber.from(this.amountOut.quotient.toString()),
      endAmount: BigNumber.from(this.amountOut.quotient.toString())
    };
    const swapFee = this.swapFee && {
      token: this.amountOut.currency.isNative ? AddressZero : this.amountOut.currency.address,
      recipient: this.swapFee.recipient,
      startAmount: BigNumber.from(this.amountOut.multiply(this.swapFee.percent).quotient.toString()),
      endAmount: BigNumber.from(this.amountOut.multiply(this.swapFee.percent).quotient.toString())
    };
    const outputs = swapFee ? [swapperOutput, swapFee] : [swapperOutput];
    const nowSecs = Math.floor(Date.now() / 1e3);
    return new IDutchOrderTrade({
      currencyIn: this.amountIn.currency,
      currenciesOut: [this.amountOut.currency],
      orderInfo: {
        reactor: UNISWAPX_REACTOR,
        swapper: options?.swapper ?? this.swapper,
        deadline: (nowSecs + this.deadlineBufferSecs) * 1e3,
        additionalValidationContract: AddressZero,
        additionalValidationData: "0x",
        nonce: options?.nonce ?? BigNumber.from(0),
        // decay timings dont matter at all
        decayStartTime: nowSecs,
        decayEndTime: nowSecs,
        exclusiveFiller: AddressZero,
        exclusivityOverrideBps: BigNumber.from(0),
        input: {
          token: this.amountIn.currency.isNative ? AddressZero : this.amountIn.currency.address,
          startAmount: BigNumber.from(this.amountIn.quotient.toString()),
          endAmount: BigNumber.from(this.amountIn.quotient.toString())
        },
        outputs
      },
      tradeType: this.tradeType
    });
  }
  get inputAmount() {
    return this.amountIn;
  }
  get outputAmount() {
    return this.amountOut;
  }
  /** For UniswapX, handling token taxes in the output amount is outsourced to quoters */
  get postTaxOutputAmount() {
    return this.outputAmount;
  }
  get totalGasUseEstimateUSD() {
    return this.wrapInfo.needsWrap ? this.wrapInfo.wrapGasEstimateUSD : 0;
  }
  get classicGasUseEstimateUSD() {
    return 0;
  }
  // no decay for limit orders
  get startTimeBufferSecs() {
    return 0;
  }
  // no decay auction for limit orders
  get auctionPeriodSecs() {
    return 0;
  }
  get executionPrice() {
    return new Price(this.amountIn.currency, this.amountOut.currency, this.amountIn.quotient, this.amountOut.quotient);
  }
  worstExecutionPrice() {
    return this.executionPrice;
  }
  maximumAmountIn() {
    return this.inputAmount;
  }
  minimumAmountOut() {
    return this.outputAmount;
  }
}
export var QuoteState = /* @__PURE__ */ ((QuoteState2) => {
  QuoteState2["SUCCESS"] = "Success";
  QuoteState2["NOT_FOUND"] = "Not found";
  return QuoteState2;
})(QuoteState || {});
export var PoolType = /* @__PURE__ */ ((PoolType2) => {
  PoolType2["V2Pool"] = "v2-pool";
  PoolType2["V3Pool"] = "v3-pool";
  return PoolType2;
})(PoolType || {});
export var SwapRouterNativeAssets = /* @__PURE__ */ ((SwapRouterNativeAssets2) => {
  SwapRouterNativeAssets2["MATIC"] = "MATIC";
  SwapRouterNativeAssets2["BNB"] = "BNB";
  SwapRouterNativeAssets2["AVAX"] = "AVAX";
  SwapRouterNativeAssets2["ETH"] = "ETH";
  SwapRouterNativeAssets2["MON"] = "MON";
  return SwapRouterNativeAssets2;
})(SwapRouterNativeAssets || {});
export var URAQuoteType = /* @__PURE__ */ ((URAQuoteType2) => {
  URAQuoteType2["CLASSIC"] = "CLASSIC";
  URAQuoteType2["DUTCH_V1"] = "DUTCH_LIMIT";
  URAQuoteType2["DUTCH_V2"] = "DUTCH_V2";
  URAQuoteType2["PRIORITY"] = "PRIORITY";
  return URAQuoteType2;
})(URAQuoteType || {});
